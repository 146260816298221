body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #000000;
}
@media (min-width: 900px){
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh; 
  }
}

@media (max-width: 900px){
  #root {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);
    max-height: 100vh; 
  }
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

select {
  font-size: 58px !important;  
  text-align: center;
  text-align-last: center;
}


#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}